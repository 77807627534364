<template>
  <div class="warp">
    <div class="detail">
      <h3 class="title">{{ detail.title }}</h3>
      <div class="content" v-html="detail.content"></div>
    </div>
    <el-button class="but" type="primary" @click="toBack"> 关闭</el-button>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getAgreementDetails } from "@/api/login";
import { setInfo, setToken } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {
      detail: {},
      type: 1, //注册  2:购买协议
      classId: null,
    };
  },
  created() {
    Bus.$emit("handleRegister", false);
    this.type = this.$router && this.$router.query && this.$router.query.type ? 2 : 1;
    this.classId =
      this.$router && this.$router.query && this.$router.query.classId ? this.$router.query.classId : null;
    if (this.type == 1) {
      getAgreementDetails("", 1).then((res) => {
        if (res.code == 0) {
          if (res.msg) {
            this.detail = res.msg;
          }
        }
      });
    } else {
      getPcAgreement(this.classId).then((res) => {
        if (res.code == 0) {
          if (res.msg) {
            this.detail = res.msg[0];
          }
        }
      });
    }
  },
  methods: {
    toBack() {
      // this.$router.go(-1);
      this.$router.push("/");
      // Bus.$emit("handleRegister", true);
    },
  },
};
</script>
<style lang="less" scoped>
.warp {
  padding: 20px 0px;
  background: #fff;
}
.detail {
  width: 1200px;
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
}
.but {
  margin: 20px auto;
  display: block;
}
</style>
